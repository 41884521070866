.financial-form-section {
  border: 1px solid #33333e;
  padding: 2rem;

  .financial-body-container {
    margin-top: 1.5rem;
  }
}

.financial-form-title {
  span {
    color: #a4a3b1;
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
  }
  border-bottom: 0.5px solid #a4a3b1;
  padding-bottom: 1rem;
}

.financial-form-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  .form-lables {
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 6px;
    span {
      margin-right: 0.2rem;
    }
  }
}

.savebtn-btn {
  margin-top: 2rem;
  display: inline-block;
}
