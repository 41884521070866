.create-new-scenario {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.create-new-scenario-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
}

.create-new-scenario-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.create-new-scenario-header-stepper {
  width: 60%;
}

.create-new-scenario-content {
  display: flex;
  flex: 1;
  height: 60%;
}

.create-new-scenario-content-body {
  // width: 75%;
  padding: 2rem;

  overflow: auto;
}

.create-new-scenario-content-body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span {
    color: #dedee2;
    font-size: 18px;
    font-weight: 400;
  }

  & button {
    font-size: 16px !important;
    font-weight: 400;
  }
}

.create-new-scenario-content-body-scope {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  & span {
    color: #dedee2;
    font-size: 16px;
    font-weight: 400;
  }

  & .decarb-select {
    width: 15rem;
    flex: unset;
  }
}

.create-new-scenario-content-body-scope-filter {
  margin-top: 10px;

  & .decarb-select {
    width: 11rem;
    float: right;
  }

  & .decarb-select button {
    height: auto !important;
    padding: 5px 10px 5px 10px;
  }
  & .motif-select-input-text {
    font-size: 14px;
  }
}

.create-new-scenario-content-body-emissions-traj-chart {
  margin-top: 4rem;
}

.create-new-scenario-content-body-waterfall-header {
  color: #dedee2;
  font-size: 16px;
  font-weight: 400;
  margin-top: 2rem;
}

.create-new-scenario-content-body-waterfall-chart {
  margin-top: 3rem;
}

.create-new-scenario-content-body-business-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  row-gap: 1.5rem;
  flex-wrap: wrap;

  & .motif-card-orientation-horizontal {
    width: 30%;
    height: 12rem;
    padding-bottom: 10px;
  }

  & .motif-card-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-start;

    & .motif-h6 {
      font-size: 16px;
    }

    & span {
      color: #737387;
    }
  }

  & .motif-card-body {
    overflow-y: hidden;
    padding-top: 20px;
  }

  .summary-percent {
    font-size: 30px;
    font-weight: 500;
    color: white !important;
  }

  .forecast-summary-loading {
    height: 250px;
  }
}

.forecast-import-excel-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;

  border-top: 1px solid #3f3f4c;
}
.save-new-scenario-content-body-row {
  display: flex;
  column-gap: 2rem;
  padding-bottom: 1rem;
}

.save-new-scenario-content-body-name-title {
  width: 12rem;
  color: #a4a3b1;
  font-weight: 400;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-items: center;
  column-gap: 0.3rem;
}
.save-new-scenario-content-body {
  width: 100%;
  padding: 2rem;
  overflow: auto;
  margin-top: 1rem;
}

.save-new-scenario-content-body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #dedee2;
}
