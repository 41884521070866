.project-filter {
  height: 100%;
  // width: 30%;
  background-color: #23232f;
  overflow: auto;
  border-right: 1px solid #33333e;
  padding: 2rem;
  width: 42%;
}

.project-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.project-filter-header-h {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.project-filter-header-main {
  color: #dedee2;
  font-size: 20px;
  font-weight: 400;
}

.project-filter-header-desc {
  color: #a4a3b1;
  font-size: 14px;
}

.project-filter-header-buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;

  & button {
    padding: 8px;
    width: fit-content;
    min-width: fit-content;
  }
}

.project-filter-search {
  margin-top: 1rem;
}

.project-filter-tree {
  margin-top: 2rem;

  & .motif-icon-button {
    display: none;
  }

  & .motif-checkbox-label-wrap {
    align-items: center;
  }
  // & .motif-checkbox-custom {
  //   border-radius: 1px;
  //   border-color: #8a8a9b;
  //   background-color: #23232f;
  // }

  & .motif-tree-nodes li {
    margin-bottom: 0;
  }

  & .motif-tree-node {
    margin: 0;
    padding: 0;
  }
}

.project-filter-tree > ul > li > ul {
  margin-top: 1rem;
}

.project-filter-tree > ul > li > ul > li {
  border-top: 1px solid #8a8a9b;
}

.project-filter-tree > ul > li > ul > li > div {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.project-filter-tree > ul > li > ul > li > ul > li > div {
  margin-left: 5rem !important;
  margin-bottom: 0.5rem !important;
}

.decarb-tree-label {
  color: #dedee2;
  font-size: 14px;
  font-weight: 400;

  p {
    display: inline-block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 1rem;
    padding: 0.1rem 0.5rem;
    color: #a4a3b1;
    background: #3f3f4c;
    font-size: 12px;
    border-radius: 16px;
  }
}

.project-filter-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;

  & button {
    font-size: 16px !important;
    font-weight: 400;
  }
  .decarb-loading-indicator-embed{
    width: auto !important;
  }
}

@media screen and (max-width: 1680px) {
  .project-filter-header-buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;

    & button {
      padding: 8px;

      & svg {
        width: 18px;
        height: 18px;
        color: #a4a3b1;

        & path {
          stroke: #a4a3b1;
        }
      }
    }
  }
}

@media screen and (min-width: 1681px) {
  .project-filter-header-buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;

    & button {
      padding: 8px;

      & svg {
        color: #a4a3b1;

        & path {
          stroke: #a4a3b1;
        }
      }
    }
  }
}
