.report-container {
  height: 93%;
  padding: 0.5rem;
  width: 100%;
}

iframe {
  border: none;
}
.powerbi-button {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
}
.motif-toggle-switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.toggleButtonPowerBi {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 0.5rem;
}
