.decarb-side-menu {
  position: fixed;
  left: 0;
  height: 100%;
  z-index: 1;
  background-color: #1a1a24;
  border-right: 1px solid #33333e;

  & .motif-vertical-navigation-menuitem {
    background-color: #1a1a24;
  }

  & .motif-vertical-navigation-menuitem:hover {
    background-color: #2e2e38;
    z-index: 100;
  }

  & .motif-vertical-navigation-menuitem-iconcontainer {
    & path {
      stroke: #a4a3b1 !important;
    }
  }
}

.motif-vertical-navigation-menuitem-portal {
  width: 256px;
  height: 40px;
  z-index: 2;
  position: absolute;
  background-color: #2e2e38;
}
.motif-vertical-navigation .motif-vertical-navigation-footer {
  display: flex;
  visibility: visible;
  position: fixed;
  bottom: 1rem;
  border: none;
  background-color: transparent;
  overflow-x: hidden;
}
.hide-decarbLibrary {
  display: none;
}
